@import url(https://fonts.googleapis.com/css?family=Lato);
.rc-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 80; }
  .rc-backdrop.invert {
    background: rgba(1, 1, 1, 0.7); }

.range-picker-input {
  display: inline-flex;
  border: 1px solid #e4e4e4;
  width: 300px; }
  .range-picker-input * {
    box-sizing: border-box; }
  .range-picker-input__icon {
    display: inline-flex;
    align-items: center; }
  .range-picker-input__start, .range-picker-input__end {
    display: inline-flex;
    flex: 1 1; }
    .range-picker-input__start .picker-input.range input, .range-picker-input__end .picker-input.range input {
      width: 100%;
      border: none; }

.picker-input {
  display: inline-block;
  position: relative; }
  .picker-input__icon {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: 10px;
    display: inline-flex;
    align-items: center; }
  .picker-input__text {
    padding: 10px;
    border: 1px solid #e4e4e4;
    outline: none;
    font-size: 16.8px; }
    .picker-input__text:disabled {
      background: #e4e4e4; }
  .picker-input__clear {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: 10px;
    cursor: pointer; }

.picker {
  display: inline-block; }
  .picker__container {
    position: absolute;
    z-index: 100; }
    .picker__container.portal {
      position: fixed;
      top: 50%;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
              transform: translateX(-50%) translateY(-50%); }
    .picker__container__include-time {
      border: 1px solid #e4e4e4; }
      .picker__container__include-time .calendar__item,
      .picker__container__include-time .time__container {
        border: none; }
    .picker__container__tab {
      margin: 10px 0; }
      .picker__container__tab button {
        padding: 5px 10px;
        outline: none;
        display: inline-flex;
        align-items: center;
        background: none;
        border: none;
        border-bottom: 2px solid #e4e4e4; }
        .picker__container__tab button.active {
          color: #e64a19;
          border-bottom: 2px solid #e64a19; }
        .picker__container__tab button:first-child {
          border-right: none; }
        .picker__container__tab button svg {
          margin-right: 5px; }

.time__container {
  display: inline-flex;
  align-items: center;
  border: 1px solid #e4e4e4;
  padding: 15px;
  background: white;
  font-family: 'Lato';
  font-size: 12px; }
  .time__container__div {
    margin: 0 10px; }
  .time__container__type {
    display: flex;
    flex-direction: column;
    margin-left: 10px; }

.time-input {
  display: inline-block;
  width: 40px;
  overflow: hidden; }
  .time-input__up, .time-input__down {
    border: 1px solid #e4e4e4; }
    .time-input__up button, .time-input__down button {
      outline: none;
      width: 100%;
      cursor: pointer;
      border: none; }
  .time-input__text {
    width: 100%;
    border-left: 1px solid #e4e4e4;
    border-right: 1px solid #e4e4e4;
    box-sizing: border-box; }
    .time-input__text input {
      width: 100%;
      box-sizing: border-box;
      border: none;
      font-size: 15px;
      padding: 5px;
      text-align: center;
      outline: none; }

.calendar {
  display: inline-block;
  background: white;
  font-size: 12px; }
  .calendar *, .calendar *:before, .calendar *:after {
    box-sizing: border-box; }
  .calendar__container {
    width: 270px;
    font-family: 'Roboto', sans-serif;
    display: none; }
  .calendar__list {
    display: table; }
  .calendar__item {
    display: table-cell;
    border: 1px solid #ececec; }
    .calendar__item:not(:first-child) {
      border-left: none !important; }
  .calendar--show {
    display: inline-block; }
  .calendar__head {
    position: relative;
    background: #008489;
    padding: 10px 6px; }
    .calendar__head--title {
      font-size: 1.3em;
      color: white;
      text-align: center;
      margin: 4px; }
    .calendar__head--button {
      outline: none;
      border: none;
      cursor: pointer;
      background: none;
      font-size: 20px; }
      .calendar__head--button svg {
        fill: white; }
    .calendar__head--prev, .calendar__head--next {
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center; }
    .calendar__head--prev {
      left: 0; }
    .calendar__head--next {
      right: 0; }
  .calendar__panel--show {
    display: block !important; }
  .calendar__panel--today {
    background: #018f95;
    padding: 5px;
    display: none; }
    .calendar__panel--today h2 {
      margin: 0;
      cursor: pointer;
      font-size: 12px;
      text-align: center; }
  .calendar__body--table {
    width: 100%;
    table-layout: fixed;
    text-align: center;
    border-spacing: none;
    border-collapse: collapse; }
    .calendar__body--table th {
      height: 30px;
      vertical-align: middle;
      color: #212121; }
  .calendar__day {
    vertical-align: top;
    padding-top: 5px;
    height: 40px;
    cursor: pointer; }
    .calendar__day:hover:not(.calendar__day--disabled) {
      background: #018f95;
      cursor: pointer; }
    .calendar__day--0 {
      color: red; }
    .calendar__day--6 {
      color: blue; }
    .calendar__day--today {
      background: #ffffff; }
    .calendar__day--disabled {
      color: #ddd;
      cursor: initial; }
    .calendar__day--start, .calendar__day--end, .calendar__day--selected {
      background: #008489;
      color: #ffffff; }
      .calendar__day--start:hover, .calendar__day--end:hover, .calendar__day--selected:hover {
        background: #008489; }
    .calendar__day--range {
      background: #01c0c8; }
    .calendar__day--text {
      display: block;
      font-size: 10px; }
  .calendar__year, .calendar__month {
    height: 55px;
    vertical-align: middle; }
    .calendar__year:hover, .calendar__month:hover {
      background: #018f95;
      cursor: pointer; }

#test1 .calendar {
  width: 90% !important; }

#test1 .calendar__container {
  width: 100%; }

.calendar__day--selected {
  text-decoration: line-through;
  background: #b9b1ae; }

#test1 .calendar__body--table th {
  height: 60px; }

#test1 .calendar__day {
  vertical-align: middle;
  padding: 40px;
  font-size: 20px;
  border: 1px solid #e5e5e5; }

#test1 .calendar__head {
  padding: 15px 6px; }

.calendar__day--range {
  background: #66e2da;
  color: #ffffff; }

.calendar__day {
  border: 1px solid #f1f1f1; }

.calendar__day:hover,
.calendar__day:focus {
  background: #e93a53;
  color: #ffffff; }

.calendar__day:hover:not(.calendar__day--disabled) {
  background: #e93a53;
  color: #ffffff; }

.calendar__day--6,
.calendar__day--0 {
  color: #212121; }

.calendar__body--table th {
  font-weight: normal;
  letter-spacing: 0.7px;
  font-family: "Cereal-book";
  height: 45px; }

.calendar__head--button {
  border: 0px !important; }

.calendar__container {
  width: 35em; }

.calendar__head {
  background-color: #e93a53; }

.calendar__item {
  border: 1px solid #170f161c !important; }

.calendar__day {
  vertical-align: top;
  padding: 1em;
  /* height: 47px; */
  cursor: pointer;
  width: 100px; }

.booking-create .calendar__container {
  width: 20em !important; }

.package-content {
  padding: 0px 2em;
  border: 1px solid #edefed;
}

